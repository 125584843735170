import React from 'react';
import './App.css';
import SearchBar from './components/nav/SearchBar';
import Container from '@material-ui/core/Container';

const Layout = (props: any) => {
    return (
        <Container className="App" maxWidth={false} disableGutters>
        <SearchBar></SearchBar>
            <main>{props.children}</main>
        </Container>
    );
  };

export default Layout;