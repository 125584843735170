import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.css';
import Layout from './layout';
import reportWebVitals from './reportWebVitals';
import { createTheme, Theme, ThemeProvider } from '@material-ui/core/styles';
import HomePage from './pages/home';
import MusicPage from './pages/music';
import Error404 from './pages/error';

const theme: Theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ffa726',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'rgba(0,0,0,0)'
        },
      }
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Squada One"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 20,
  },
});

const Root = () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Layout>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/music" component={MusicPage} />
            <Route component={Error404} />
          </Switch>
      </Layout>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

ReactDOM.render(
  <Root/>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
